.page-viewer {
  padding-top: 15px;
  padding-bottom: 0;
  background: $c-white;
}

.page-viewer .item-chart {
  width: 100%;
}

.public-form {
  .submiting {
    h3 {
      text-align: center;
    }
  }
  .markdownEditor {
    .tab-nav {
      visibility: hidden;
    }
  }
}
