aside,
.entity-viewer {
  .filelist,
  .attachments-list-header {
    padding-left: 15px;
  }

  h2 {
    font-size: $f-size;
    position: relative;
    letter-spacing: 0.1em;
    .property-help {
      text-transform: none;
    }
    small {
      position: absolute;
      top: -2px;
      left: 0;
      height: 16px;
      line-height: 15px;
      border: 1px solid $c-grey;
      color: $c-grey-dark;
      width: 16px;
      text-align: center;
      border-radius: 50%;
    }
  }
}
