.toc {
  padding: 0px 15px 15px 15px;
}

div.tocHeader {
  padding-left: 15px;
  border-bottom: 1px solid #f4f4f4;
}

.toc-view{
  margin: 0;
  padding: 0;
  list-style: none;
}

.toc-view-link {
  display: block;
  padding: 5px 0;
  font-size: $f-size;
  color: $c-black;
}

.toc-edit {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0;

  div {
    width: 100%;
    margin: 0 5px;
  }
}

.toc-indent:first-child .toc-view-link{
  padding-top: 0;
}

.toc-indent-0 .toc-view-link,
.toc-indent-1 .toc-view-link {
  font-weight: bold;
}

.toc-indent-0 { padding-left: 0% }
.toc-indent-1 { padding-left: 7% }
.toc-indent-2 { padding-left: 14% }
.toc-indent-3 { padding-left: 21% }
.toc-indent-4 { padding-left: 28% }
.toc-indent-5 { padding-left: 35% }

.toc-indent-0 .toc-indent-less,
.toc-indent-9 .toc-indent-more {
  display: none;
}
