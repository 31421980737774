.semantic-search-list-item:hover {
  background-color: #dee7ff4d;
}

.semantic-search-form {
  input {
    margin-right: 15px;
  }
}

.semantic-search-results-viewer h3 {
  display: inline;
}

.semantic-search-button {
  position: absolute;
  padding: 11px 15px;
  top: 0;
  right: -170px
}

.edit-semantic-search {
  float: right;
}

.semantic-search-button:focus {
  outline: 0;
}

.semantic-search-list-item {
  border-bottom: 1px solid $c-grey-light;
  padding: 20px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .uw-progress-bar {
    margin-top: 5px;
  }

  .item-header {
    display: flex;
    flex-direction: row;

    .title {
      width: 80%;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .btn {
    height: 21px;
    width: 21px;
    border-radius: 50%;
    // border-radius: 50%;
    // line-height: 40px;
    // font-size: 1.1em;
    // padding: 0;
    margin: 0 6px;
    flex-grow: 0;
  }
}

.semantic-search-filters {
  margin-top: 16px;
  border-top: 1px solid $c-grey-light;

  .semantic-search-filters-item {
    padding: 20px 30px;
    border-bottom: 1px solid $c-grey-light;

    .item-header {
      margin-bottom: 15px;
    }
  }
}

.uw-progress-bar {
  height: 9px;
  border-radius: 5px;
  border: 1px solid $c-info-light;
  overflow: hidden;

  &--progress {
    background-color: $c-type-5;
    height: 100%;
  }

  &--counter {
    margin-top: 5px;
    text-align: center;
  }
}

.semantic-search-help {
  padding: 16px;
  margin-top: 32px;
}
