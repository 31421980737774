@mixin relationshipsFooterButtons {
  .relationships-right-buttons {
    float: right;
  }
  .relationships-left-buttons {
    width: 50%;
  }
}

footer {
  margin: 30px 0 0;
  padding: 20px 0 15px;
  text-align: left;
  color: $c-grey;
  border-top: 1px solid $c-grey-light;

  a {
    color: $c-grey;
  }

  @media (min-width: 1024px) {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.footer-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 20px;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    justify-content: center;
  }

  &_item {
    display: inline-block;
    padding-left: 15px;
    position: relative;
    @media (min-width: 1024px) {
      padding: 0 15px;
    }
  }

  &_separator {
    display: inline-block;
    padding-right: 0;
    width: 100%;
    height: 1px;
    margin: 15px 0;
    border-bottom: 1px solid $c-grey-light;
    @media (min-width: 1024px) {
      width: auto;
      height: auto;
      margin: 0;
      border-left: 1px solid $c-grey-light;
      border-bottom: 0;
    }
  }
}

.footer-logo img {
  height: 20px;
}
.footer-tooltip {
  @include tooltip('top');
  padding: 15px;

  .footer-nav_item:hover & {
    display: block;
    width: 280px;
  }

  img {
    margin: 0 0 3px 2px;
    height: 25px;
  }

  p {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .footer-collection_name {
    width: auto;
  }
}

/* ----------------------------------------- */
/* Sticky Footers                            */
/* ----------------------------------------- */

.entity-footer,
.library-footer,
.settings-footer,
.sidepanel-footer {
  min-height: $FooterHeight;
  border-top: 1px solid #ddd;
  padding: 10px 16px 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2;

  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;

  .footer-extra-row {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px dashed #ccc;
  }

  .btn-cluster {
    display: flex;
    flex-wrap: wrap;

    &.lg-margin-left-12 {
      @media (min-width: 1024px) {
        margin-left: 12px;
      }
    }

    .btn {
      margin-right: 12px;
    }

    &.content-right {
      text-align: right;
      margin-left: auto;

      .btn:last-child {
        margin-right: 0;
      }
    }

    &.sm-order-1,
    .sm-order-1 {
      order: -1;
    }
  }

  .btn {
    padding: 4px 8px;
    margin-bottom: 11px;
    color: $c-black;

    span.translation {
      max-width: calc(min(100vw - 80px, 250px));
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      position: relative;
      margin-top: -10px;
      top: 5px;
    }

    &.btn-danger,
    &.btn-primary,
    &.btn-success {
      color: #fff;
    }

    &.btn-default {
      background-color: #eceff1;
      border-color: #cfd8dc;

      &:hover {
        border-color: #3655ba;
        color: #3655ba;
      }
    }

    &.btn-plain {
      background-color: unset;
      border-color: transparent;
    }
  }

  .btn-label {
    padding: 0 5px;
  }

  &:hover {
    .btn.btn-footer-hover-success {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;

      &:hover {
        background-color: #449d44;
        border-color: #398439;
      }
    }
  }
}

.toggle-footer-button {
  border: transparent;
  border-radius: 12px 12px 0px 0px;
  webkit-box-shadow: 0px 6px 0px 0px white, 0px -1px 7px -1px grey;
  -moz-box-shadow: 0px 6px 0px 0px white, 0px -1px 7px -1px grey;
  box-shadow: 0px 6px 0px 0px white, 0px -1px 7px -1px grey;
  padding: 4px 12px;
  height: 24px;
  left: 100px;
  background-color: $c-white;
  z-index: 1;
  width: max-content;
  height: max-content;
  max-width: 150px;
  min-width: 90px;
  min-height: 24px;
}

.library-footer {
  padding: 0;
  border-top: none;
  background-color: transparent;

  @media (max-width: 1023px) {
    &.closed {
      display: none;
    }
  }

  .btn-cluster {
    -webkit-box-shadow: outset 0px -4px 3px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: outset 0px -4px 3px rgba(50, 50, 50, 0.75);
    box-shadow: 10px 0px 1px white, 2px -2px 4px lightgray;
    padding: 10px 10px 0px 10px;
    width: 100%;
    background-color: white;
  }

  .close-actions-button {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    left: 49%;
    width: 98%;
    text-overflow: ellipsis;
    background-color: transparent;

    @media (min-width: 1024px) {
      display: none;
    }
  }
}

.open-actions-button {
  display: flex;
  position: fixed;
  bottom: 0px;
  justify-content: center;
  left: 49%;
  width: 150px;
  text-overflow: ellipsis;
  margin-left: -75px;
  height: max-content;
  min-height: 18px;

  &.closed {
    display: none;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.entity-footer,
.library-footer,
.settings-footer {
  width: 100%;
  position: fixed;

  &.remove-nesting {
    margin-left: -15px;
  }

  &.remove-extra-nesting {
    margin-left: -30px;
  }

  &.with-sidepanel {
    @media (min-width: 1024px) {
      width: calc(100% - 400px);
    }
  }
}

.settings-footer {
  margin-left: -15px;

  &.with-sidepanel {
    @media (min-width: 1024px) {
      width: calc(#{$SettingsContentWidth} - 400px);
    }
  }
}

.sidepanel-footer {
  position: absolute;
  width: 100%;
  bottom: 0;

  .btn:disabled {
    cursor: default;
    opacity: 0.7;
  }
  .btn:disabled .btn-label {
    color: $c-grey !important;
  }

  @include relationshipsFooterButtons();
}

@media (max-width: 1023px) {
  .entity-footer,
  .library-footer,
  .settings-footer,
  .sidepanel-footer {
    .sm-footer-extra-row {
      width: 100%;
      margin-bottom: 10px;
      border-bottom: 1px dashed #ccc;
    }
  }
}

@media (min-width: 1024px) {
  .settings-footer {
    width: $SettingsContentWidth;
  }

  .sidepanel-footer {
    width: 400px;
    @include relationshipsFooterButtons();
  }

  .entity-footer,
  .library-footer,
  .settings-footer,
  .sidepanel-footer {
    .btn-cluster.sm-order-1,
    .btn-cluster .sm-order-1 {
      order: unset;
    }
  }

  .entity-footer,
  .library-footer,
  .settings-footer,
  .modal-footer {
    .btn {
      &.btn-extra-padding {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}
