.thesauri-values {
  margin-top: 25px;

  .panel {
    display: table;
    width: 100%;
  }
  .list-group {
    margin: 0;
  }
  .list-group-item {
    display: -webkit-flex;
    display: flex;
    border-radius: $border-radius !important;
    border: 1px solid $c-grey-light !important;
    box-shadow: $box-shadow !important;
  }
  .list-group-item > div {
    width: 100%;
  }
  .list-group-item .list-group-item {
    margin-left: 0;
    margin-right: 0;
  }
  .no-properties {
    text-align: center;
    font-size: $f-size;
    color: $c-grey;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 15px;
  }
  .no-properties-wrap {
    display: block;
    padding: 15px 0;
    border: 1px dashed $c-grey;
  }
  .list-group-item > svg,
  .list-group-item > span svg {
    margin: 8px 10px 0 0;
    color: $c-grey;
  }
  .list-group-item:hover > svg {
    color: $c-grey-dark;
  }
  .fa-reorder + div {
    width: 100%;
  }

  .group {
    .list-group {
      margin-left: -25px;
    }
  }
}

.thesauri-values {
  .list-group-item {
    margin: 7px 0;
    &:first-of-type {
      margin-top: 15px;
    }
    &:last-of-type {
      margin-bottom: 15px;
    }
    .no-properties {
      background: $c-white;
      padding: 0 0 10px;
    }
    .btn {
      float: right;
    }
  }

  .list-group-item .form-control {
    border: 1px solid #fff;
    box-shadow: none;

    &:focus {
      border-color: #66afe9;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  .list-group-item button {
    position: absolute;
    top: 15px;
    right: 25px;
    &.rounded-icon-small {
      right: 0px;
    }
  }

  .no-properties {
    display: none;
  }
}

.draggable {
  cursor: move;
}

.thesauri-list {
  table {
    empty-cells: hide;
    width: 100%;
    white-space: nowrap;
    text-align: justify;
    border-collapse: separate;

    border: 0px;
  }
  .nameCol {
    width: 100%;
  }
  th,
  td {
    padding: 7px 10px;
  }
  td {
    text-align: right;
    color: $c-grey;
    font-size: $f-size-xs;
  }
  th {
    background-color: $c-white;
  }
  th:not(:last-of-type) {
    border-right: 1px solid $c-white;
  }
  tr:not(:last-of-type) {
    border-bottom: 1px solid $c-grey-light;
  }

  th[scope='row'] {
    max-width: calc(100% - 330px);
    white-space: normal;
    overflow-wrap: break-word;
  }

  td:nth-child(2) {
    border-right: 1px solid $c-grey-light;
  }

  .vertical-line {
    border-right: $c-grey-light;
  }

  .thesaurus-suggestion-count {
    padding-right: 5px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
