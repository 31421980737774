.hero {
  text-align: center;
  margin: -15px -15px 30px;
  @if $hero == light {
    color: $c-black;
    background-color: $c-grey-light;
  } @else {
    color: $c-white;
    background-color: $c-full-white;
  }

  h1 {
    font-size: 36px !important;
    padding: 120px 0;
    margin: 0 auto;
    max-width: 900px;

    @if $hero == dark {
      text-shadow: 3px 3px 15px $c-black;
    }
  }

  p {
    letter-spacing: 0.1em;
    margin-bottom: 30px;
    @if $hero == dark {
      text-shadow: 3px 3px 15px $c-black;
    }
  }

  .btn {
    font-size: $f-size-lg;
    @if $hero == dark {
      box-shadow: 3px 3px 15px rgba($c-black, .3);
    }
  }
  .btn:not(:first-of-type) {
    margin-left: 15px;
  }
  .btn .fa {
    margin-left: 15px;
  }
}

.hero-stats {
  width: 100%;
  display: flex;

  a {
    width: 100%;
    border-top: 3px solid $c-white;
    padding: 60px 0;
    background: transparentize($c-black, .6);

    &:hover {
      background: transparentize($c-black, .5);
      cursor: pointer;
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }

    @if $hero == light {
      color: $c-black;
    } @else {
      color: $c-white;
      text-shadow: 3px 3px 15px $c-black;
    }
  }

  h2 {
    font-size: $f-size-xxl;
    border: 0;
    padding: 0;
    margin: 0;
    @if $hero == light {
      color: $c-black;
    } @else {
      color: $c-white;
      text-shadow: 3px 3px 5px $c-black;
    }
  }
}
