@mixin pillItem ($background: white, $color: black) {
  .pill {
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 26px;
    padding: 2px 8px;
    display: inline-block;
    margin: 2px 8px 2px 0;
    font-size: smaller;

    background: $background;
    color: $color;
  }
}
