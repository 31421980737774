.alert-wrapper {
  z-index: 9;

  .alert {
    display: block;
    width: 50%;
    left: 25%;
    box-shadow: $box-shadow;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.alert {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 0;

  & > svg:first-child {
    display: block;
    margin-right: 10px;
    float: left;
  }

  .fa-times {
    position: absolute;
    right: 10px;
    transform: translateY(3px);
    color: $c-grey;
    top: 5px;
  }

  &.no-margin {
    margin: 0px;
  }

  .alert-text {
    float: left;
  }
}

.alert-success,
.alert-warning,
.alert-danger {
  background: $c-white;
  color: $c-grey-dark;
  .alert-title {
    margin-right: 5px;
  }
}

.alert-success {
  border-left: 5px solid $c-success;
  & > svg:first-child,
  .alert-title {
    color: $c-success;
  }
}

.alert-warning {
  border-left: 5px solid $c-warning;
  & > svg:first-child,
  .alert-title {
    color: $c-warning;
  }
}

.alert-danger {
  border-left: 5px solid $c-danger;
  & > svg:first-child,
  .alert-title {
    color: $c-danger;
  }
}

.alert.alert-vertical {
  display: flex;
  flex-direction: column;

  & > svg:first-child {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.list-group .alert {
  margin: 0;
}

.sidepanel-body {
  .alert {
    background: $c-warning-light;
  }

  .alert-info {
    background: $c-info-light;
  }
}

.modal {
  .btn-default {
    opacity: 0.5;
  }
}

.modal-content {
  border-radius: 0;
  border: 0;
  box-shadow: $box-shadow;

  .cs-loader {
    padding: 50px;
  }
}

.modal-success {
  border-left: 5px solid $c-success;
}
.modal-warning {
  border-left: 5px solid $c-warning;
}
.modal-danger {
  border-left: 5px solid $c-danger;
}

.notification {
  margin-top: 15px;
  .fa {
    margin-right: 5px;
  }
}
