.nprogress-container {
  height: 4px;
  margin-bottom: -4px;
}

#nprogress {
  transition: all 0ms !important;
  .bar {
    height: 4px;
    background: $c-primary;
    position: absolute;
  }

  .peg {
    display: none;
  }
}
