form {
  .highlight {
    .multiselect {
      background-color: $c-card-highlight;
      border: 1px solid $c-shadow;
      border-radius: 6px;
      padding: 0 3px;
    }

    input,
    select,
    textarea {
      background-color: $c-card-highlight;
    }
  }
}
