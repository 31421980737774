.panel-default {
  border-color: $c-grey-light;

  .panel-heading {
    font-weight: bold;
    border: none;
    background-color: $c-grey-lighten;
    padding: 7px 10px;

    a {
      margin-right: 10px;
      color: inherit;

      .translation {
        display: none;
      }
    }
  }
}

.panel > .list-group .list-group-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 24px;
  border-color: $c-grey-light;
  border-left-color: transparent;
  outline: none;
  box-shadow: none;
}

.list-group-item-actions {
  white-space: nowrap;
}

.list-group {
  .property-name:hover {
    cursor: move;
  }

  .propery-form {
    height: 0;
    width: 100%;
    border: 1px solid $c-grey-light;

    &.expand {
      height: auto;
      padding: 15px 30px;
      margin-top: -8px;
      cursor: default;
    }

    & > div > .well:not(:last-child),
    & > div > .row:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.NavlinksSettings {
  .list-group {
    .propery-form {
      border: none;
    }
  }
}

.panel {
  .list-group {
    list-style: none;

    div.list-group-item:first-child {
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .list-group-item {
      small {
        margin-left: 5px;
        font-size: $f-size;
        color: $c-grey-dark;
        text-decoration: none;
      }
      .btn:not(:first-child) {
        margin-left: 5px;
      }

      &.error {
        display: block;
        border: 1px solid $c-danger !important;
        box-shadow: $box-shadow-danger !important;

        .property-name,
        .property-name .fa {
          color: $c-danger;
        }

        .validation-error {
          margin-right: 5px;
        }
      }

      &.dragging {
        opacity: 0;
      }
    }
  }
}

.panel-footer {
  display: block;
}
