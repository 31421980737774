.map-container {
  /*!rtl:ignore*/
  direction: ltr;
}

.library-map {
  overflow: hidden;

  .documents-counter {
    & > span {
      padding: 5px;
      background-color: #fff;
      border-color: #ccc;
      border-style: solid;
      border-width: 1px;
      border-radius: 10px;
    }
  }
}
