.geolocation {
  .form-row {
    margin-top: 10px;
    .form-group {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      float: left;
      width: 100%;

      &.half-width {
        max-width: 50%;
      }

      label {
        display: inline-block;
      }

      input {
        width: -moz-available;
        width: -webkit-fill-available;
      }
    }
  }

  .overlays {
    cursor: pointer;
  }
}

.map-container {
  width: 100%;
  height: 100%;
  z-index: 1;

  .leafletmap {
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}
