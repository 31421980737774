.breadcrumbs {
	display: none;
	padding: 7px 15px;
	border-bottom: 1px dotted $c-grey;
	color: $c-grey-dark;
	font-size: $f-size-sm;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	b {
		color: $c-black;
	}
	.fa {
		margin: 0 10px;
	}
	.fa-home {
		margin: 0;
	}
	a {
		color: $c-grey-dark;
	}
}
