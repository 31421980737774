.public-form {
  .attachments-list {
    .drop-zone {
      width: 100%;
      height: 186px;
      border: 1px dashed #6d6c7c;
      border-radius: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
    label {
      display: flex;
      justify-content: center;

      .text-content {
        width: 240px;
        color: #818181;

        #icon {
          font-size: 3em;
          color: #ddd;
        }
      }
    }
    input {
      display: none;
    }
    #button {
      border: 1px solid #ddd;
      background-color: white;
      border-radius: 4px;
      height: 30px;
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding-top: 0.9vh;
      cursor: pointer;
    }
  }
  .preview-list {
    ul {
      padding: 5px 0px 5px 0px;
      list-style: none;
      font-size: 12px;

      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        border: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 0px 5px 0px 5px;
        border-radius: 4px;
        height: 32px;

        .preview-title {
          width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        div > span {
          border: 1px solid #ca5c54;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 3px 6px 3px 6px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .dropzone {
    width: 100%;
  }
}
