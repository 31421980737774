.filelist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    label {
      margin-top: 5px;
    }
  }
}
