.settings {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: auto;
  @media (min-width: 1024px) {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.settings-navigation,
.settings-content {
  width: 100%;
}

@media (min-width: 1024px) {
  .settings-navigation {
    width: $SettingsNavigationWidth;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid $c-grey-light;
  }

  .settings-content {
    position: relative;
    width: $SettingsContentWidth;
    overflow-y: auto;
  }
}

.settings {
  background: $c-white;

  .panel {
    border: 0;
    margin: 0;
    box-shadow: none;
  }
}

.settings-navigation {
  background: $c-background;

  .panel {
    background-color: transparent;
  }

  .panel-heading {
    background-color: transparent;
    text-transform: uppercase;
    font-size: $f-size-sm;
    letter-spacing: 0.1em;
    padding-left: 20px;
    border: 0;
    border-top: 1px solid $c-grey-light;
  }
  .panel-heading:first-of-type .panel-heading {
    border-top: 0;
  }

  .list-group-item {
    border: 0;
    padding-left: 20px;
    background-color: transparent;
    &:hover,
    &:focus {
      color: $c-black;
      background-color: transparent;

      &:before {
        content: '';
        display: block;
        width: 5px;
        background: $c-primary;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
    }

    &.active,
    &.active:hover,
    &.active:focus {
      color: $c-primary;
      font-weight: bold;
      background: $c-grey-lighten;
      cursor: default;

      &:before {
        content: '';
        display: block;
        width: 5px;
        background: $c-primary;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }

  .fa {
    margin-right: 5px;
  }
}

.settings-content {
  padding: 15px;
  margin-bottom: $FooterHeight;

  &.without-footer {
    margin-bottom: 0px;
  }

  &.footer-extra-row {
    margin-bottom: calc(#{$FooterHeight} * 2 + 2px);
  }

  .panel-heading {
    background-color: transparent;
    font-size: $f-size-xxl;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;

    input {
      font-size: $f-size-xl;
    }
  }

  .panel-subheading {
    display: flex;
    background-color: transparent;
    font-size: $f-size-xl;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  h2 {
    padding: 16px 0;
    font-size: $f-size-xl;
    font-weight: 500;
    border-bottom: 1px solid $c-grey-light;
  }

  .list-group-item:last-of-type {
    border-bottom: 0;
  }

  .document-types {
    .list-group-item {
      a {
        flex: 1;
      }

      span {
        flex: 1;
      }
    }
  }
}

@media (max-width: 1023px) {
  .settings-content {
    &.sm-footer-extra-row {
      margin-bottom: calc(#{$FooterHeight} * 2 + 2px);
    }
  }
}

// Improve this

.settings-content {
  .thesauri,
  .metadata,
  .relationType,
  .EditTranslationForm,
  .NavlinksSettings,
  .account-settings,
  .page-creator,
  .user-creator,
  .FiltersForm,
  .FiltersForm-list {
    //height: 100%;
  }
}

.settings-content form,
.settings-content main,
.settings-content main > div,
.settings-content .panel {
  //min-height: 100%;
}

.settings-content .account-settings form {
  //height: auto;
}

.settings-content .panel:last-of-type,
.settings-content .page-creator .panel,
.settings-content .account-settings .panel,
.settings-content .user-creator .panel,
.settings-content .EditTranslationForm .panel {
  margin-bottom: 0;
}

.settings-content .account-settings > .panel > .panel-body,
.settings-content form > .panel > .panel-body,
.settings-content form > .panel > .list-group,
.settings-content > .panel > .panel-body,
.settings-content > .panel > .list-group {
  // height: calc(100% - 51px);
  // padding-bottom: 85px;
  // overflow-y: scroll;
  div {
    @include pillItem;
  }
  .user-details {
    div {
      margin-bottom: 7px;
    }
    div.user-details-role {
      text-transform: capitalize;
    }
  }
}

.settings-content .page-creator .tab-content-visible {
  margin-bottom: 20px;
}

.settings-content .page-creator .entity-view {
  margin-bottom: 20px;
  .translation {
    margin-right: 10px;
  }
}

.settings-content {
  .side-panel {
    #metadataForm {
      margin-bottom: 0px;
    }
  }
}

.page-creator,
.relationType,
.thesauri,
.metadataTemplate {
  .panel-heading {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 13px;
    padding-left: 2px;
    .form-group,
    input {
      margin: 0;
    }
    .form-group {
      width: 100%;
      position: relative;
    }
    .validation-error {
      position: absolute;
      top: 0px;
      right: 15px;
      line-height: 34px;
      height: 32px;
    }
  }

  .property-type-warning {
    font-style: italic;
    margin-left: 5px;

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
}

.custom-uploads {
  .uploading {
    font-size: $f-size-lg;
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.85);

    svg {
      margin-right: 8px;
    }
  }

  ul {
    padding-left: 0px;
    li {
      display: flex;

      div.info {
        display: flex;
        flex-direction: column;
        width: calc(100% - 80px);
        padding-left: 15px;
        padding-bottom: 20px;
        align-items: flex-start;
        justify-content: space-between;

        span {
          font-weight: bold;
          font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
          border-radius: 3px;
          max-width: 100%;
          overflow-x: auto;
          white-space: nowrap;

          &.thumbnail-url {
            padding: 4px 8px;
            border: 1px solid #ddd;
          }
        }
      }
    }
    button {
      padding: 1px 5px;
      font-size: $f-size-sm;
      line-height: 1.5;
      border-radius: 3px;
      color: #fff;
      background-color: #d9534f;
      border: 1px solid #d43f3a;
      &:hover {
        background-color: #cd2d28;
      }
    }
  }

  .thumbnail {
    width: 95px;
    height: 95px;
    min-width: 95px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #ddd;
    }
  }
}

.collection-settings {
  .form-element.row {
    margin: 0;
    padding: 5px 0 15px 0;

    &:hover {
      background-color: #efefef;
    }

    .row {
      padding-top: 0px;
      padding-bottom: 10px;
    }
  }

  .inline-form-label {
    font-size: 16px;
    line-height: 32px;

    &.larger-label {
      padding-left: 15px;
      padding-right: 15px;
      width: 250px;
      float: left;
    }
  }

  .form-element-inputs {
    display: block;

    &.smaller-inputs {
      padding-left: 15px;
      padding-right: 15px;
    }

    .col-no-gutters {
      margin-left: -15px;
      margin-right: -15px;
    }

    .multiselect {
      margin-top: -8px;
    }
  }

  .toggle-children-button {
    margin-top: 2px;
    margin-right: 15px;
    margin-bottom: 5px;
  }

  .toggle-children-children {
    flex: 1;
  }

  .property-description {
    text-align: left;
    font-size: medium;
    width: 400px;
    transform: translateX(15px) translateY(100%);
    z-index: 2000;

    &::after {
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateX(-75%) translateY(55%) rotate(0.25turn);
    }
  }

  @media (min-width: 768px) {
    .form-element.row {
      padding: 5px 0 5px 0;
    }

    .form-element-inputs {
      display: flex;
    }

    .toggle-children-button {
      margin-bottom: 0px;
    }
  }
}

.new-thesaurus-modal,
.new-relationshipType-modal {
  border-radius: 6px;

  h3 {
    margin-top: 10px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid $c-grey-light;
  }

  .description {
    color: $c-grey-dark;
  }

  .error {
    margin-top: 5px;
    font-weight: bold;
    color: $c-type-0;
  }

  .modal-footer {
    text-align: left;
  }
}
