body {
  direction: ltr;
}

#app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 50px);
  padding-top: 0;
  overflow: hidden;
}

.container {
  max-width: 960px;
}
.container-fluid {
  margin-right: 0;
  margin-left: 0;
  flex: 1;
  height: 100%;
}

@media (max-width: 1023px) {
  .col-xs-12 {
    width: 100%;
  }
}

.app-content {
  overflow: hidden;
}

.app-content > div {
  height: 100%;
}

.force-ltr {
  /*!rtl:ignore*/
  direction: ltr;
}

.force-rtl {
  /*!rtl:ignore*/
  direction: rtl;
}
