@import '../../scss/config/_colors.scss';
@import '../../scss/config/_materials.scss';
@import '../../scss/config/_typography.scss';

.modal-dialog {
  max-height: 90%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;

  .btn-default {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.modal-tip-dim {
  width:  900px;
}

.modal-content {
  border-radius: 0;
  border: 0;
  box-shadow: $box-shadow;
}

.modal-success {
  border-left: 5px solid #8bc74c;
}
.modal-info {
  border-left: 5px solid #cce5ff;
}
.modal-warning {
  border-left: 5px solid orange;
}
.modal-danger {
  border-left: 5px solid #ed353d;
}
.modal-export {
  max-width: 350px;
  .modal-content {
    padding: 10px;
    border-radius: 4px;
    border: 0;
    box-shadow: $box-shadow;
  }
  h3 {
    text-align: center;
  }
  p {
    margin: 10px;
  }
  .captcha {
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
  .buttons {
    padding: 10px;
    display: flex;
    justify-content: center;
    input {
      width: 130px;
      border: 1px solid rgba(0, 0, 0, 0.4);
    }
    input.btn:nth-child(1) {
      margin-right: 10px;
    }
  }
}

.modal-footer {
  .left {
    float: left;
  }
}
