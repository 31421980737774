.slider {
    position: relative;
    width: 100%;
}

.slider-buttons {
    position: absolute;
    top: -40px;
    right: 0px;
}

.slider-btn {
    width: 30px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    border: 1px solid #ccc;
    font-size: $f-size-xl;

    &:hover {
        background: #eee;
        cursor: pointer;
    }

    &:not(:last-child) {
        margin-right: 5px;
    }
}

.slider-items {
    display: flex;
}
