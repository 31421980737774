.metadataTemplate-sync {
  margin-bottom: 10px;

  svg:first-child {
    color: $c-warning;
  }
}

.metadataTemplate-heading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .form-group {
    flex: 1;
    margin-right: 15px;

    .form-control {
      line-height: 23px;
      padding: 9px 12px;
      margin-bottom: 0;
    }
  }
  .form-group {
    margin: 0;
    position: relative;
  }
  .validation-error {
    position: absolute;
    top: 0px;
    right: 15px;
    line-height: 34px;
    height: 32px;
  }
}

.metadataTemplate-pageview {
  margin-top: 10px;
  margin-bottom: 10px;

  .form-group {
    div:first-child {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      label {
        margin-right: 20px;
      }
    }
  }
}
.metadataTemplate-pageview > div:nth-child(1) > div:nth-child(1) .metadataTemplate-list {
  padding-bottom: 85px;
  margin: 0;
}

.metadata {
  @include draggable;
}

.property-atributes {
  opacity: 0.3;
  &.is-active {
    opacity: 1;
  }
  .fa {
    margin-right: 5px;
  }
  .fa-warning {
    display: none;
    color: $c-warning;
  }

  .row {
    margin-bottom: 8px;
  }

  .property-atributes {
    opacity: 0.3;
    cursor: pointer;
    display: table;
    width: 100%;
    position: relative;
    padding: 0;
    border-radius: $border-radius;
    font-weight: normal;
    color: $c-primary;
    &.is-active {
      opacity: 1;
    }
    span {
      margin: 0 5px;
    }
    > span:first-of-type {
      margin-left: 0;
    }
    .fa {
      margin-right: 5px;
    }
    .fa-angle-right {
      margin: 0;
    }
    .fa-info-circle {
      color: $c-grey;
      margin: 0 8px 0 0;
      font-size: $f-size-lg;
      float: right;
      transition: all 300ms;
    }
    &:hover > .fa-info-circle {
      color: $c-black;
    }
  }

  .tooltip.top {
    right: 35px;
    left: auto !important;
    transform: translateX(50%);
  }
}

.property-label {
  cursor: pointer;
}
.property-help {
  margin-left: 5px;
  position: relative;
  color: $c-grey-dark;
}
.no-margin {
  margin: 0 !important;
}

.property-description {
  @include tooltip('top');
}
.property-description-bottom {
  @include tooltip('bottom');
  z-index: 9999;
}
.property-description-top-left {
  @include tooltip('top-left');
  transform: translate(-175px, -5px);
}

.property-description-right {
  @include tooltip('right');
}
.property-description-left {
  @include tooltip('left');
}
.property-description-top-left:after {
  transform: translateX(175px);
}

.property-description,
.property-description-bottom,
.property-description-right,
.property-description-left,
.property-description-top-left {
  width: 200px;
  white-space: normal;

  .property-help:hover & {
    display: block;
  }
}

.nested-properties {
  margin-top: 15px;
  margin-bottom: 15px;

  .input-group {
    margin-bottom: 5px;
  }
  .input-group-addon {
    line-height: 16px;
  }
  .form-control {
    margin-bottom: 0 !important;
  }
}
