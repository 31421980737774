.attachments-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 95vw;
  max-width: 1440px;
  height: 95vh;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: $c-white;
  padding: 16px 24px;
  outline: none;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  &__header {
    padding: 4px 0 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    padding: 5px 8px 4px 8px;
    border: 1px solid #555555;
    border-radius: 2px;
    background: $c-white;

    svg {
      margin-right: 4px;
    }

    span {
      line-height: 1;
    }

    &:disabled,
    .disabled {
      opacity: 0.5;
    }
  }

  &__content {
    flex: 1;
    overflow: hidden;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__tabs {
    display: flex;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #dddddd;

    .tab-link {
      display: inline-block;
      margin: 0 0 -1px;
      padding: 8px 16px;
      border-bottom-color: transparent;
      cursor: pointer;

      &-active {
        border: 1px solid #dddddd;
        border-bottom-color: $c-white;
      }
    }
  }

  &__tabs-content {
    flex: 1;
    overflow: auto;
    overflow-x: hidden;

    .tab-content {
      padding: 24px 0 0 0;
      height: 100%;

      &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }

      .wrapper-web {
        width: 100%;
        max-width: 350px;

        input,
        .form-group {
          margin-bottom: 4px;
        }

        .btn {
          width: 100%;
        }

        .has-feedback .property-help {
          position: absolute;
          top: 7px;
          right: 7px;
          width: 17px;
          color: $c-primary;
        }
      }

      .media-grid {
        width: calc(100% + 20px);
        max-width: calc(100% + 20px);
        margin-left: -10px;

        .row {
          display: flex;
          flex-wrap: wrap;
        }

        &-item {
          width: 100%;
          flex-basis: 100%;
          margin-bottom: 15px;
          padding-left: 10px;
          padding-right: 10px;
          cursor: pointer;

          @media (min-width: 768px) {
            width: 33.3333%;
            flex-basis: 33.3333%;
          }

          @media (min-width: 1024px) {
            width: 25%;
            flex-basis: 25%;
          }
        }

        &-card {
          background: #ffffff;
          border: 1px solid #dddddd;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
          padding: 6px;

          &.active {
            border-color: $c-type-9;
            border-width: 2px;
          }

          &-header {
            h5 {
              margin: 0;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
            }

            span {
              font-weight: 500;
              font-size: 10px;
              line-height: 12px;
              color: #888888;
            }
          }

          &-content {
            padding-top: 85%;
            position: relative;
            overflow: hidden;

            .media {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              overflow: hidden;

              img {
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
                background-color: #fafafa;
              }

              .video-container {
                height: 100%;

                > div:first-child {
                  padding-bottom: initial;
                  overflow: initial;
                  height: 100%;
                  display: flex;
                  margin-top: 0 !important;
                  margin-bottom: 0 !important;

                  .react-player {
                    height: auto !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-title {
      margin-top: 24px;
      margin-bottom: 4px;
      font-size: $f-size-lg;
    }
  }

  &-trigger {
    border-radius: 2px;
  }

  @include desktop {
    width: 80vw;
    height: 70vh;
  }

  &-progress {
    width: 400px;
    height: 32px;
  }

  &-progress-bar {
    width: 59%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
  }

  .select-from-link {
    display: flex;
    align-items: baseline;
    gap: 10px;
    .form-group {
      margin: unset;
    }
  }

  .upload-options {
    display: flex;
    align-items: baseline;
    gap: 16px;
  }
}
