@import '../config/colors';
@import '../elements/tooltip';

header {
  width: 100%;
  z-index: 6;
  position: fixed;
  top: 0;
  min-height: $header-height;
  justify-content: space-between;
  background: $c-theme;
  border-bottom: 1px solid $c-grey-light;

  h1 {
    float: left;
    font-size: $f-size;
    font-family: $f-mono;
    font-weight: 300;
    margin: 0;
    width: calc(100% - 2 * 54px);
    @media (min-width: 454px) {
      padding: 0 15px;
      width: auto;
      max-width: 320px;
    }

    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: $header-height;
      height: $header-height;
      @if $theme == light {
        color: $c-black !important;
      } @else {
        color: $c-white !important;
      }
    }
  }

  .menu-button {
    z-index: 1;
    float: left;
    width: $header-height;
    height: $header-height;
    line-height: $header-height;
    background: transparent;
    border: 0;
    padding: 0 15px;
    text-align: center;
    font-size: $f-size-lg;
    cursor: pointer;

    @if $theme == light {
      color: $c-black;
    } @else {
      color: $c-white;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }
  .searchBox {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.menuNav {
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 1023px) {
    position: fixed;
    width: 85vw;
    max-width: 360px;
    min-width: 250px;
    top: 50px;
    height: calc(100% - 50px);
    background-color: var(--c-bg-menu-mobile);
    color: var(--c-text-menu-mobile);
    border-top: 1px solid transparentize($c-black, 0.6);
    overflow: auto;
    transition: all 225ms;
    flex-direction: column;
    &.is-active {
      left: 0px;
      display: flex;
    }
    &:not(.is-active) {
      display: none;
    }
    padding-bottom: 35px;
  }
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height;

    .Dropdown:not(.is-active) {
      .Dropdown-label {
        @if $theme != light {
          color: $c-white;
        }
      }

      a {
        @if $theme == light {
          color: $c-black;
        } @else {
          color: $c-white;
        }
      }
    }
  }

  .dropdown-menu {
    &.expanded {
      display: flex;
      flex-direction: column;
      position: absolute;
      li {
        display: flex;
        flex-direction: column;
        a {
          padding: 8px 10px;
          text-align: left;
        }
      }
    }
  }

  .no-i18nmenu {
    padding-top: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    @media (min-width: 1024px) {
      white-space: nowrap;
    }
  }

  .Dropdown {
    top: 19px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;

    .Dropdown-option {
      padding-right: 5px;
    }
  }

  .hide {
    display: none;
  }

  .menuNav-I18NMenu {
    width: 170px;

    display: flex;

    @media (min-width: 1024px) {
      float: left;
      border-top: 0;
      border-right: 1px solid transparentize($c-black, 0.6);
      border-left: 1px solid transparentize($c-black, 0.6);
    }

    @media (max-width: 1023px) {
      width: 100%;
      padding: 20px 15px;
    }

    .menuNav-btn {
      font-size: $f-size-sm;
    }

    .menuNav-language {
      display: flex;
      position: relative;
      align-self: center;
      text-transform: none;
      width: inherit;
      gap: 10px;

      @media (min-width: 1024px) {
        height: $header-height;
      }

      @media (max-width: 1023px) {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        color: inherit;
        background-color: transparent;

        .dropdown-menu {
          &.expanded {
            top: 23px;
          }
        }
      }

      button {
        border: none;
        background: none;
      }

      .live-translate {
        display: flex;
        gap: 10px;
        align-items: center;

        @media (max-width: 1023px) {
          display: none;
          pointer-events: none;
        }
      }

      .dropdown-menu {
        font-size: 1em;

        &.expanded {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: inherit;

          li {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }
      }

      .menuNav-item {
        padding: 0;

        a,
        .translation {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .live-on {
        color: #88eacd;
      }

      .live-off {
        color: #ffe66b;
      }

      button.singleItem {
        padding-left: 1em;
      }

      button.dropdown {
        display: flex;
        padding-left: 1em;
        width: 100%;
        padding-right: 1em;
        justify-content: space-between;
        align-items: center;
        @media (min-width: 1024px) {
          height: $header-height;
        }
      }

      .menuNav-item {
        button {
          padding: 8px;
        }
      }

      span.singleItem {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
      }

      .singleItem {
        @media (min-width: 1024px) {
          color: $c-white;
          align-self: center;
        }
      }
    }
  }
}

.menuNav::before {
  @media (max-width: 1023px) {
    top: 50px;
    left: calc(min(85vw, 360px));
    z-index: 10;

    content: '';
    display: block;
    opacity: 0.4;
    min-height: 60%;
    width: 120%;
    overflow: visible;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    background-color: $c-filter-film;
    -webkit-filter: contrast(125%) brightness(75%);
    filter: contrast(125%) brightness(75%);
  }
}

.menuItems {
  flex-grow: 1;

  @media (max-width: 1023px) {
    display: flex;
    overflow: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    order: 1;
    padding-left: 15px;
    flex-direction: column;

    ::-webkit-scrollbar {
      height: 12px;
      width: 12px;
      background: var(--c-bg-menu-mobile);
    }

    ::-webkit-scrollbar-thumb {
      background: var(--c-bg-menu-mobile);
      -webkit-border-radius: 1ex;
      border-radius: 1ex;
      -webkit-box-shadow: 0px 1px 2px var(--c-bg-menu-mobile);
      box-shadow: 0px 1px 2px var(--c-bg-menu-mobile);
    }

    ::-webkit-scrollbar-corner {
      background: var(--c-bg-menu-mobile);
    }
  }

  @media (min-width: 1024px) {
    max-width: calc(100% - 300px);
  }
}

.mobile-menuActions {
  @media (max-width: 1023px) {
    order: 2;
  }
}

.menuNav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuNav-item {
  position: relative;
  padding: 0;
  width: 100%;
  padding-bottom: 5px;

  @media (min-width: 1024px) {
    display: inline-block;
    width: auto;
    min-width: $header-height;

    .tab-link-label {
      @include tooltip('bottom');
    }

    &:hover {
      .tab-link-label {
        display: block;
      }
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    width: auto;
    flex-grow: 1;
    width: 95%;

    button {
      display: flex;
      gap: 10px;
      color: inherit;

      .fa-caret-down {
        order: -1;
        height: inherit;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      &.expanded {
        .fa-caret-down {
          order: -1;
          height: inherit;
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0);
          transform: rotate(0);
        }
      }

      .tab-link-label {
        padding: 0;
        line-height: 1em;
      }
    }

    .dropdown-menu {
      &.expanded {
        display: flex;
        background-color: inherit;
        color: inherit;
        box-shadow: none;
        border: 0;
        position: relative;
        padding-left: 20px;
        flex-direction: column;

        li {
          display: flex;
          flex-direction: column;
          a {
            text-align: left;
            padding: 8px 10px;
            width: 100%;
            color: inherit;
            &:hover {
              background-color: var(--c-menu-highlight);
              width: 95%;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.menuNav-btn {
  display: block;
  border: 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  min-width: $header-height;
  height: $header-height;
  padding: 0 15px;
  background-color: transparent;

  @media (min-width: 1024px) {
    line-height: $header-height + 2px;
    @if $theme == light {
      color: $c-black;
    } @else {
      color: $c-white;
    }
  }

  @media (max-width: 1023px) {
    line-height: 38px;
    height: 2.65em;
    text-align: left;
    &:not(:hover) {
      color: inherit;
    }
    &.active-link,
    &:hover {
      border: 1px;
      color: var(--c-text-active-link) !important;
      border-radius: 5px;
    }
    &.active-link {
      background-color: var(--c-bg-active-link);
    }
    &:hover {
      background-color: var(--c-menu-highlight);
    }
  }

  &:hover,
  &:active,
  &:focus,
  .menuNav-item.is-active & {
    @media (min-width: 1024px) {
      background-color: transparent;
      @if $theme == light {
        color: $c-black;
        border-color: $c-black;
      } @else {
        border-color: $c-white;
        color: $c-white;
      }
    }
    @media (max-width: 1023px) {
      color: inherit;
    }
  }
}

.menuActions {
  padding-top: 5px;

  @media (min-width: 1024px) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .menuNav-list {
    display: flex;
    @media (min-width: 1024px) {
      overflow: visible;
    }
    @media (max-width: 1023px) {
      overflow: visible;
      flex-direction: column;
      padding-left: 15px;
      border-radius: 5px;
      border: 5px;

      .menuNav-item {
        height: 2.65em;
        width: 95%;
        a {
          display: inline-flex;
          align-items: center;
          gap: 10px;
          color: inherit;

          &.active-link {
            color: var(--c-text-active-link);
          }
        }
      }
    }
  }
}
