$desktop-width: 768px;
$desktop-width-1: $desktop-width - 1;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$desktop-width-1}) {
    @content;
  }
}

@mixin big {
  @media (min-width: 1200px) {
    @content;
  }
}
