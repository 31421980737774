/** @format */

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 54px;
}

.login-title {
  width: 60%;
  margin: $header-height auto;
  display: block;
  img {
    width: 100%;
  }
}

.login-password {
  position: relative;
  padding-bottom: 30px;
  max-height: 100px;
  transition: max-height 300ms, padding 300ms;
  overflow: hidden;

  &.is-hidden {
    max-height: 0;
    padding-bottom: 0;
  }
}

.form-text {
  margin-top: 10px;
  text-align: center;
  span {
    margin: 0;
    position: static;
  }
  a,
  span.button {
    cursor: pointer;
    text-decoration: underline;
    color: $c-grey-dark;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  a.label-danger {
    color: $c-danger;
    opacity: 1;
    font-weight: bold;
  }
}
