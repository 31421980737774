.recharts-responsive-container,
.recharts-wrapper {
  /*!rtl:ignore*/
  direction: ltr;
}

.recharts-tooltip-cursor {
  fill: #f80 !important;
  opacity: 0.1 !important;
}

.recharts-default-tooltip {
  .recharts-tooltip-item-value {
    white-space: break-spaces !important;
  }
}

.recharts-legend-item {
  cursor: pointer;
}
