.navLinks {
  @include draggable;
}

.NavlinksSettings {
  .list-group-item {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .panel .list-group .list-group-item .propery-form.expand {
    padding-left: 0;
    padding-right: 0;
  }
  .list-group-item .reorder {
    color: $c-grey;
    margin-right: 16px;
  }
  .list-group-item:hover .reorder {
    color: $c-grey-dark;
  }
}
