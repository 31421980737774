@mixin tooltip ($direction) {
  display: none;
  position: absolute;
  width: auto;
  margin: 0;
  padding: 8px;
  line-height: 1.2em;
  text-align: center;
  font-family: $f-regular;
  font-size: $f-size-sm;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: initial;
  background-color: transparentize(#FFF7E2, .10);
  color: $c-black;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 12px 24px rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  z-index: 9999;

  @if $direction == 'top' {
    top: auto;
    right: auto;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-5px);
  }
  @if $direction == 'top-left' {
    top: auto;
    right: auto;
    bottom: 100%;
    left: auto;
    transform: translateY(-5px);
  }
  @if $direction == 'bottom' {
    top: 100%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%) translateY(5px);
  }
  @if $direction == 'left' {
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateX(-5px) translateY(-50%);
  }
  @if $direction == 'right' {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 100%;
    transform: translateX(5px) translateY(-50%);
  }

  &:after {
    content: '';
    position: absolute;

    @if $direction == 'top' {
      top: 100%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    @if $direction == 'top-left' {
      top: 100%;
      right: auto;
      bottom: auto;
      left: 5px;
      transform: translateX(0);
    }
    @if $direction == 'bottom' {
      top: auto;
      right: auto;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    @if $direction == 'left' {
      top: 50%;
      right: auto;
      bottom: auto;
      left: 100%;
      transform: translatey(-50%);
    }
    @if $direction == 'right' {
      top: 50%;
      right: 100%;
      bottom: auto;
      left: auto;
      transform: translatey(-50%);
    }
  }
}