.library-viewer {
  background-color: #eaeaea33;

  @media (max-width: 1023px) {
    padding-bottom: 51px;
  }
}

.order-by-arrow {
  color: $c-primary;
  font-size: $f-size-lg;
  width: 15px;
  height: 13px;
  line-height: 18px;
  cursor: pointer;
  margin-left: 5px;
  text-align: center;
  overflow: hidden;
  position: relative;
  top: -1px;

  &:hover {
    color: $c-black;
  }

  &.fa-long-arrow-down:before {
    position: relative;
    bottom: 5px;
  }
}

.Dropdown.is-active {
  .order-by-arrow {
    display: none;
  }
}

.library-map > .map-container {
  position: absolute;
  top: 0;
  left: 0;
}

.buttons-group {
  .btn {
    border-radius: 50%;
  }
  .btn:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.library-map-layout {
  padding: 0;
  height: calc(100% + 15px);
  width: calc(100% + 30px);
  margin: -15px -15px 0;

  .search-list {
    padding: 15px;
  }

  .documents-counter {
    z-index: 1;
    position: relative;
    top: -18px;
    left: 20px;
    color: #555;
    text-shadow: 1px 1px #fff;
    font-size: $f-size-sm;
    b {
      color: #555;
    }
  }
}

.list-view-mode {
  position: absolute;
  z-index: 4;
  right: 0px;
  top: 0px;
  display: flex;

  .buttons-group {
    margin-left: 8px;

    .btn {
      height: $header-height * 0.88;
      line-height: $header-height * 0.88;
      width: $header-height * 0.88;
      padding: 0;
      text-align: center;
      position: relative;
    }

    .btn.is-active {
      background-color: $c-type-9;
      color: white;
    }
  }

  .number-of-markers {
    margin-left: 3px;
    font-size: 13px;
  }

  .list-view-mode-zoom {
    display: none;

    @media (min-width: 600px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    top: 16px;
  }
}
