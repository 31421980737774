.upload-box {
  display: block;
  position: relative;
  max-width: 960px;
  margin: 0 auto 30px;
  text-align: center;

  &_wrapper {
    display: block;
    position: relative;
    font-size: $f-size-lg;
    padding: 25px;
    margin-bottom: 15px;
    color: $c-grey-dark;
    border: 2px dashed $c-grey-light;

    svg {
      color: $c-grey-dark;
      margin-right: 10px;
    }

    a,
    button {
      border: 0;
      border-bottom: 1px dotted $c-grey-dark;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      color: $c-grey-dark;

      &:hover {
        color: $c-black;
        border-bottom: 1px solid $c-grey-dark;
        text-decoration: none;
      }
    }
  }
}

.upload-box .protip {
  opacity: 0.6;
}

.is-uploaded {
  opacity: 0;
  transition: all 300ms;
  position: relative;
  top: 15px;

  &.is-active {
    opacity: 1;
    top: 0;
  }
}

.is-uploading {
  position: absolute;
  opacity: 0;
  transition: all 300ms;
  text-align: center;
  font-size: $f-size-lg;
  color: $c-grey-dark;
  width: 600px;

  &.is-active {
    opacity: 0.4;
  }
}

.required {
  position: relative;
  top: -3px;
}

.content-header ~ .library-filters {
  @media (min-width: 1024px) {
    top: 101px;
  }
}

.filelist {
  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .upload-button {
    margin-left: 15px;
    color: $c-black;
    background-color: $c-grey-lighten;
    border-color: $c-grey-lighten;

    &.upload-failed {
      color: $c-white;
      background-color: $c-danger;
      border-color: $c-danger;
    }

    &.upload-success {
      color: $c-white;
      background-color: $c-success;
      border-color: $c-success;
    }
  }

  .badge {
    margin-bottom: 15px;
  }

  .btn {
    border-radius: 2px;
    margin-right: 15px;
  }

  .file-failed {
    .translation {
      margin-right: 15px;
    }
  }
}

.file,
.file-form {
  padding: 15px;
  border-bottom: 1px solid #f4f4f4;

  &-originalname {
    margin-bottom: 15px;
  }

  &-failed {
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    padding: 3px;
    color: $c-danger;
  }
}

.file-form {
  padding: 0;
  overflow: hidden;
  .form-group {
    padding: 0;
    > div {
      margin-bottom: 15px;
    }
  }
}
