.logotype a {
    background-image: url('/public/tom_lantos/minority-forum-logo-white.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 32px;
    width: 170px;
    text-indent: -9999px;
    overflow: hidden;
  }

  .footer-nav_item {
    height: 70px;
    line-height: 70px;
  }

  .footer-collection_name {
    width: 130px;
    background-image: url('/public/tom_lantos/tom_lantos_logo_grey_en.png');
    background-repeat: no-repeat;
    background-position: center 4px;
    background-size: 115px 65px;

    span {
      display: none;
    }
  }

  span.typeLabel{
      margin-left: 10px;
      border: 1px solid grey;
      border-radius: 5px;
      padding: 3px 3px;
      background-color: grey;
      color: white;
  }

  /* Hirek */
  .newsEnd{ border: 1px solid #d7d7dc; padding: 20px; margin-top: 20px; margin-bottom: 10px;}
  .markdown-viewer .newsEnd .pageTitle,
  .pageTitle{ color: #000; font-size: 32px; line-height: 38px; margin: 0 0 5px;}
  .newsEnd__date{ font-size: 14px; line-height: 1em; margin-bottom: 30px; background: #eee; display: inline-block; padding: 5px 8px; font-weight: bold; color: #9e9e9e; border-radius: 2px;}
  .newsEnd__img{margin-bottom: 30px;}
  .newsEnd__img img{ border: 5px solid #eee;}
  .statText p{ font-size: 16px; line-height: 1.5em; }
  .newsEnd__lead{background: #eee; padding: 10px 15px; margin-bottom: 25px;}
  .newsEnd__lead p{font-size: 16px; line-height: 1.5em; color: #888;}

  /*News listPage*/
  .newsList{
  /*flexbox*/
  display: -webkit-box;display: -moz-box;display: -webkit-flex;display: -ms-flexbox;display: flex;-webkit-box-flex: 0;-ms-flex: 0 1 auto;flex: 0 1 auto;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-ms-flex-wrap: wrap;flex-wrap: wrap;
  width: 100%;margin-top: 20px;
  }
  .newsList__item{ width: 100%; margin: 0; border: 1px solid #d7d7dc; padding: 10px;}
  @media screen and (min-width: 1024px){
      .newsList__item{ width: 48%; margin: 1%}
  }
  .newsList__item__img{display: block; margin-bottom: 20px;transition: transform .2s;}
  .newsList__item__img img{display: block; border: 5px solid #eee;}
  .newsList__item__title{display: block; color: #000; font-size: 20px; line-height: 24px; margin: 0 0 5px;}
  .newsList__item__title:hover{text-decoration: none; color: #2f75b5;}
  .newsList__item__date{font-size: 12px; line-height: 1em; margin-bottom: 20px; background: #eee; display: inline-block; padding: 5px 8px; font-weight: bold; color: #9e9e9e; border-radius: 2px;}
  .newsList__item__lead, .newsList__item__lead p{font-size: 13px; line-height: 1.5em; color: #888; text-align: justify;}
  .newsList__item__more{margin-top: 10px}
  .btn.btn--more{background: #2f75b5; color: white; font-weight: bold; border-radius: 0; }
  .btn.btn--more:hover{background: #5798d3;}

  /* Infotext */
  .library-infotext{
    font-size: 14px;
  }

  /* logobox-links */
  .logobox-links div{
    padding-top: 10px;
    font-size: 14px;
  }

  .logobox-links div.logobox-left{
    width: 49%;
  }

  .logobox-links div.logobox-left a{
    float: right;

  }

  .logobox-links div.logobox-middle{
    width: 2%;
  }

  .logobox-links div.logobox-right{
    width: 49%;
  }