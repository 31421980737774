$f-size-xs: 0.8em;
$f-size-sm: 0.9em;
$f-size: 1em;
$f-size-lg: 1.1em;
$f-size-xl: 1.2em;
$f-size-xxl: 1.6em;
$f-size-sl: 2.5em;

$f-regular: 'Roboto', sans-serif;
$f-special: 'Roboto Slab', 'Georgia', serif;
$f-mono: 'Roboto Mono', monospace;
