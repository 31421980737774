$ImgMaxHeight: 200px;
$c1: calc(100%);
$c1ImgMaxHeight: 600px;
$c2: calc(50% - 15px);
$c2ImgMaxHeight: 460px;
$c3: calc(33.333% - 15px);
$c3ImgMaxHeight: 320px;
$c4: calc(25% - 15px);
$c5: calc(20% - 15px);
$c6: calc(16.666% - 15px);
$c7: calc(14.285% - 15px);
$c8: calc(12.5% - 15px);
$c9: calc(11.111% - 15px);
$c10: calc(10% - 15px);
$c11: calc(9.09% - 15px);

.item {
  .item-group-zoom--5 & {
    @media (min-width: 600px) {
      width: $c7;
    }
    @media (min-width: 872px) {
      width: $c8;
    }
    @media (min-width: 1024px) {
      width: $c7;
    }
    @media (min-width: 1280px) {
      width: $c8;
    }
    @media (min-width: 1560px) {
      width: $c9;
    }
    @media (min-width: 1850px) {
      width: $c10;
    }
    @media (min-width: 2120px) {
      width: $c11;
    }
  }

  .item-group-zoom--4 & {
    @media (min-width: 600px) {
      width: $c6;
    }
    @media (min-width: 872px) {
      width: $c7;
    }
    @media (min-width: 1024px) {
      width: $c6;
    }
    @media (min-width: 1280px) {
      width: $c7;
    }
    @media (min-width: 1560px) {
      width: $c8;
    }
    @media (min-width: 1850px) {
      width: $c9;
    }
    @media (min-width: 2120px) {
      width: $c10;
    }
  }

  .item-group-zoom--3 & {
    @media (min-width: 600px) {
      width: $c5;
    }
    @media (min-width: 872px) {
      width: $c6;
    }
    @media (min-width: 1024px) {
      width: $c5;
    }
    @media (min-width: 1280px) {
      width: $c6;
    }
    @media (min-width: 1560px) {
      width: $c7;
    }
    @media (min-width: 1850px) {
      width: $c8;
    }
    @media (min-width: 2120px) {
      width: $c9;
    }
  }

  .item-group-zoom--2 & {
    @media (min-width: 600px) {
      width: $c4;
    }
    @media (min-width: 872px) {
      width: $c5;
    }
    @media (min-width: 1024px) {
      width: $c4;
    }
    @media (min-width: 1280px) {
      width: $c5;
    }
    @media (min-width: 1560px) {
      width: $c6;
    }
    @media (min-width: 1850px) {
      width: $c7;
    }
    @media (min-width: 2120px) {
      width: $c8;
    }
  }

  .item-group-zoom--1 & {
    @media (min-width: 600px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
    @media (min-width: 872px) {
      width: $c4;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 1024px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
    @media (min-width: 1280px) {
      width: $c4;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 1560px) {
      width: $c5;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 1850px) {
      width: $c6;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 2120px) {
      width: $c7;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
  }

  .item-group-zoom-0 & {
    @media (min-width: 600px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
    @media (min-width: 872px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
    @media (min-width: 1024px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
    @media (min-width: 1280px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
    @media (min-width: 1560px) {
      width: $c4;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 1850px) {
      width: $c5;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 2120px) {
      width: $c6;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
  }

  .item-group-zoom-1 & {
    @media (min-width: 600px) {
      width: $c1;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c1ImgMaxHeight;
      }
    }
    @media (min-width: 872px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
    @media (min-width: 1024px) {
      width: $c1;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c1ImgMaxHeight;
      }
    }
    @media (min-width: 1280px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
    @media (min-width: 1560px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
    @media (min-width: 1850px) {
      width: $c4;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
    @media (min-width: 2120px) {
      width: $c5;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
  }

  .item-group-zoom-2 & {
    @media (min-width: 600px) {
      width: $c1;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c1ImgMaxHeight;
      }
    }
    @media (min-width: 1560px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
    @media (min-width: 1850px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
    @media (min-width: 2120px) {
      width: $c4;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $ImgMaxHeight;
      }
    }
  }

  .item-group-zoom-3 & {
    @media (min-width: 600px) {
      width: $c1;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c1ImgMaxHeight;
      }
    }
    @media (min-width: 1850px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
    @media (min-width: 2120px) {
      width: $c3;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c3ImgMaxHeight;
      }
    }
  }
  .item-group-zoom-4 & {
    @media (min-width: 600px) {
      width: $c1;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c1ImgMaxHeight;
      }
    }
    @media (min-width: 2120px) {
      width: $c2;
      .item-metadata dl.metadata-type-multimedia dd img {
        max-height: $c2ImgMaxHeight;
      }
    }
  }
  .item-group-zoom-5 & {
    width: $c1;
    .item-metadata dl.metadata-type-multimedia dd img {
      max-height: $c1ImgMaxHeight;
    }
  }
}

.page-viewer .item-group-zoom-0 .item {
  width: $c3;
  .item-metadata dl.metadata-type-multimedia dd img {
    max-height: $ImgMaxHeight;
  }
}

@mixin disableButton() {
  opacity: 0.3;
  pointer-events: none;
}

.list-view-buttons-zoom {
  &--5 .zoom-out {
    @include disableButton();
  }
  &-1 .zoom-in {
    @media (max-width: 871px) {
      @include disableButton();
    }
  }
  &-1 .zoom-in {
    @media (min-width: 1024px) and (max-width: 1279px) {
      @include disableButton();
    }
  }
  &-2 .zoom-in {
    @media (max-width: 1559px) {
      @include disableButton();
    }
  }
  &-3 .zoom-in {
    @media (max-width: 1849px) {
      @include disableButton();
    }
  }
  &-4 .zoom-in {
    @media (max-width: 2119px) {
      @include disableButton();
    }
  }
  &-5 .zoom-in {
    @include disableButton();
  }

  &-0.unpinned-mode .zoom-out {
    @include disableButton();
  }
  &-2.unpinned-mode .zoom-in {
    @include disableButton();
  }
}
