.protip {

  i {
    position: relative;
    top: 2px;
    margin-right: 10px;
    font-size: $f-size-lg;
    color: $c-grey-dark;
  }

  b {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.protip-key {
  display: inline-block;
  font-family: monospace;
  border-radius: 4px;
  padding: 2px 2px 0;
  background: $c-white;
  border: 1px solid $c-grey;
  box-shadow: inset 0 -1px 0 $c-grey;
}

.metadataTemplate {
  .protip {
    padding: 5px 10px;
    margin-bottom: 12px;
    background-color: #f0f9ff;

    p {
      margin-bottom: 0px;
    }
  }
}
