.blank-state {
  color: $c-grey-dark;
  text-align: center;
  padding: 30px 0;
  
  h4 {
    font-size: $f-size-lg;
    font-weight: bold;
  }
  
  p {
    max-width: 280px;
    margin: 0 auto 20px;
  }
  
  & > .fa {
    display: block;
    font-size: $f-size-xxl;
    margin-bottom: 20px;
    color: $c-grey;
  }
  
  .btn {
    margin: 0 5px;
  }
}

.entity-viewer .blank-state {
  padding-top: 15px;
}