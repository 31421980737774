.reference-type {
  display: inline-block;
  background: rgb(173,163,236);
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 15px;
}
