@import '../../App/scss/config/_colors.scss';
@import '../../App/scss/config/_typography.scss';
@import '../../App/scss/config/_materials.scss';

.metadataTemplate-list {
  .no-properties {
    text-align: center;
    font-size: 14px;
    color: #afafaf;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 15px;
    .fa {
      margin-right: 5px;
    }
  }
  .no-properties-wrap {
    display: block;
    padding: 15px;
    border: 1px dashed #ccc;
  }
}

.property-options-list {
  li {
    padding: 0;
    span {
      padding: 10px 10px;
      display: inline-block;
      font-size: 12px;

      @media (max-width: 1310px) {
        font-size: 10px;
      }

      @media (max-width: 1080px) {
        padding: 10px 5px;
      }

      @media (max-width: 1043px) {
        font-size: 9px;
      }
    }

    button {
      padding: 10px 10px;
      border: 0;
      background-color: transparent;
      border-right: 1px solid #ccc;

      &:hover {
        background-color: #eee;
      }

      @media (max-width: 1310px) {
        font-size: 10px;
      }

      @media (max-width: 1080px) {
        font-size: 9px;
      }
    }

    .translation {
      padding: 0;
    }
  }
}

.metadataTemplate-list,
.metadataTemplate-constructor {
  .list-group-item {
    border-radius: $border-radius !important;
    border: 1px solid $c-grey-light !important;
    box-shadow: $box-shadow !important;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .fa-clone,
    .fa-arrows-v {
      margin: 0 15px 0 0;
      color: #ccc;
    }
  }
  .list-group-item:hover {
    cursor: move;

    .fa-clone,
    .fa-arrows-v {
      color: #777;
    }
  }
}

.metadataTemplate .list-group-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 23px;
  border-color: $c-grey-light;
  border-left-color: transparent;
  outline: none;
  box-shadow: none;

  .inline-group > div {
    display: inline-block;
    margin-right: 20px;
  }
}

.metadataTemplate-constructor {
  border-left: 1px solid $c-grey;
  padding-left: 30px;
}

.metadataTemplate-list,
.metadataTemplate-constructor {
  .list-group-item {
    margin: 7px 0;
    &:first-of-type {
      margin-top: 15px;
    }
    &:last-of-type {
      margin-bottom: 15px;
    }
  }

  .propery-form {
    .conflict {
      color: $c-danger-dark;
    }

    .suggestions-label {
      margin-top: 30px;
    }

    & > div {
      position: relative;
    }

    .geolocation-grouping-alert {
      background: #fff2d0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 2px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      margin-bottom: 15px;

      @media (min-width: 1023px) {
        position: absolute;
        width: 261px;
        right: 0;
      }

      p {
        font-size: 12px;
        margin: 0 0 0 8px;
      }
    }
  }
}
